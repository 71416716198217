:root {
	--dark-100: hsl(205, 8%, 20%);
	--dark-200: hsl(205, 8%, 16%);
	--dark-300: hsl(205, 8%, 2%);

	--gray-100: hsl(0, 0%, 53%);
	--gray-200: hsl(0, 0%, 43%);
	--gray-300: hsl(0, 0%, 33%);
	--gray-400: hsl(0, 0%, 23%);
	--gray-500: hsl(0, 0%, 13%);

	--white: hsl(0, 0%, 95%);
}
